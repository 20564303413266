<template>
  <div class="px-4 mt-5">
        <!-- Campo de Pesquisa -->
        <v-row dense>
            <v-text-field  v-model="searchQuery"  label="Pesquisar Admissão..."  outlined dense  prepend-inner-icon="mdi-magnify" ></v-text-field>
            <v-col md="2" > <v-btn class="mr-4 elevation-0" text @click="atualizar" >   <v-icon left>     mdi-reload   </v-icon> atualizar página </v-btn> </v-col>
        </v-row>
        <v-list dense> <v-progress-linear  v-if="isUploading"  :indeterminate="true"  color="blue"  loading-text="Carregando Informações...." ></v-progress-linear>
            
            <h3 class="blod_color mb-5">Admissão Carregadas:</h3>
        <!-- Exibir apenas os exames filtrados -->
        <v-list-item-group v-for="(exame, index) in filteredExams" :key="index">

            <v-list-item class="my-2">
            <v-list-item-content>
                <v-list-item-title class="mb-2"> 
                <span class="font-weight-bold text-primary blod_color ">Id: {{exame.id }} - {{ exame.tipodoc.nome }}</span> 
                <br>
                <span>{{ extractFileName(exame.arquivo) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                Carregado em: {{ formatDate(exame.data_carregado) }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn small color="green" class="white--text" :href="exame.arquivo" target="_blank">
                <v-icon left>mdi-download</v-icon>
                Baixar
                </v-btn>
            </v-list-item-action>
            </v-list-item>
        </v-list-item-group>

        <!-- Mensagem quando não há exames -->
        <v-alert v-if="filteredExams.length === 0" type="info" outlined class="mt-4">
           Nenhum documento encontrado
        </v-alert>
    </v-list>
  </div>
</template>

<script>
import '../../../../../assets/css/styles.css';
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc;

export default {
  data: () => withCRUDUtils({
    selectedDocumentType: null,
    uploadedDocuments: [],
    isUploading: false,
    searchQuery: "", // Adicionado para armazenar o termo de pesquisa
  }),
  props: {
    pacienteId: { type: Number }
  },
  computed: {
    // Filtra os exames com base no termo de pesquisa
    filteredExams() {
      return this.uploadedDocuments.filter(exame => {
        const fileName = this.extractFileName(exame.arquivo).toLowerCase();
        return fileName.includes(this.searchQuery.toLowerCase());
      });
    }
  },
  methods: {
    // Pegar os documentos do back
    async fetchUploadedDocuments() {
      this.isUploading = true;
      // console.log("Atualizando documentos... Paciente ID:", this.pacienteId); // Debug
      try {
        const { data } = await api.get(`/pacientes/docs/?paciente=${this.pacienteId}`);
        this.uploadedDocuments = data.filter(item => item?.tipopasta?.id === 2);
      } catch (error) {
        this.$toast.error(`Erro ao buscar documentos: ${error}`);
      } finally {
        this.isUploading = false;
      }
    },
    // Retira o caminho do nome do arquivo 
    extractFileName(url) {
      if (!url) return "";
      return decodeURIComponent(url.split('/').pop()); // Remove a URL e decodifica caracteres especiais
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
    atualizar(){
        this.searchQuery = "";
        this.fetchUploadedDocuments();
    }
  },
  mounted() {
    this.fetchUploadedDocuments();
  }
};
</script>
